.l-app {
    @apply flex flex-row;

    &__sidebar {
      @apply flex flex-shrink-0 h-screen;
      @apply overflow-y-auto;
      min-width: var(--sidebar-width);

      &--wide {
        width: 40vw;

        @screen lg {
          width: 30vw;
        }

        @screen md {
          @apply hidden;
        }

      }
    }
  
    &__top-bar {
      @apply flex flex-shrink-0 items-center px-10 justify-end;
      @apply border-gray-400 border-b bg-white; /* FIXME: move out of layout */
      height: var(--top-bar-height);
    }

    &__content {
      @apply flex-1;
      @apply flex flex-col h-screen;
    }

    &__main {
      @apply relative flex-grow;
      @apply overflow-y-auto;
    }

}

/* for use in CardGroups */
.l-grid {
  @apply grid gap-8 w-full;
  grid-template-columns: repeat(auto-fit, var(--card-width));
}