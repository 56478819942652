.c-account-switcher {
    @apply w-full;

    &__card-group {
      @apply w-auto pt-4 pb-4 pl-6;
      @apply -ml-6 -mr-6;

      /* allow vertical scrolling for account cards */
      max-height: 300px;
      overflow-y: auto;
    }
  
}