.c-copy-link {
  @apply mx-1 text-gray-600 cursor-pointer;
  font-weight: normal !important;

  &__icon {
    @apply w-5 h-5;
  }

  &:hover {
    @apply text-gray-700;
  }

}