.c-event {
    @apply mb-8 text-sm;

    &--user, &--api {
        @apply mb-6;
    }

    &--backend {
        @apply pl-20 pr-6;
    }

    &--successive {
        @apply mb-2;
    }


    &__body {
        @apply border border-gray-300 px-6 py-3 bg-white rounded-lg relative;

        .c-event--user:not(.c-event--successive) &,
        .c-event--api:not(.c-event--successive) & {
            @apply mb-3 ;

            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                left: 32px;
                bottom: -16px;
                border-width: 8px;
                border-style: solid;
            }
    
            &:after {
                border-color: white transparent transparent transparent;
                margin-bottom:1px;
            }
    
            &:before {
                border-color: var(--gray-400) transparent transparent transparent;
            }

        }
    
        .c-event--backend & {
            @apply bg-gray-200;
        }


    }

    &__byline {
        @apply text-gray-500 flex flex-row items-center pl-6;

        .c-event--backend & {
        }

        .c-event--successive &,
        .c-event--backend & {
            display: none;
        }

    }


    &__affected {
        @apply mt-2 mb-1 text-gray-600;
    }


    &__counter {
        @apply mr-2;
    }



    &__resource-link {
        @apply font-mono text-xs text-gray-600 px-1;
    }

    &__avatar {
        @apply mr-2 w-8 h-8;

        .c-event--backend & {
            @apply bg-transparent text-gray-500;
        }
    }

    &__contents {}

    &__timestamp {
        @apply inline-block text-gray-500 text-right mt-1 text-xs;
    }

    &__actioner {
        @apply text-xs;
    }

    &__separator {
        @apply my-6;
    }

}
