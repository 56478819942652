.c-dropdown {
 
  &__content {
    @apply flex flex-col p-1;
    min-width: 120px;

    > * + * {
      @apply mt-1;
    }
  }
}


