.c-sidebar {
    @apply bg-gray-800 text-white w-full h-full flex;

    &--auth {
        @apply items-center justify-end;
        background: linear-gradient(to right, var(--gray-700), var(--gray-800));
    }

    &__logo {
        width: 180px;

        .c-sidebar--login & {
            margin-bottom: 400px;
        }

        .c-sidebar--signup & {
            margin-bottom: 460px;
        }

    }

    &__content {
        @apply mr-16;
    }

    &__fancy-list {

        @apply mb-6 hidden;


        &-item {
            @apply text-lg mb-6 pl-12 relative bg-gray-700;
            min-height: 32px;

            &::before {
                @apply block absolute left-0 top-0 w-8 h-8 rounded-full;
                @apply bg-blue-500 text-white;
                content: url('../../assets/figma/tick.svg');
            }
        }

    }

    &__testimonial {}

}