.c-card {
  @apply rounded-md bg-white;
  @apply inline-block relative align-top;
  @apply p-4;

  width: var(--card-width);
  min-width: var(--card-width);

  /* Use pseudo element for borders to prevent contents moving
  about when the thickness changes */

  &::before {
    @apply block absolute rounded-md border;
    transition: border-color 0.2s ease;

    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    content: "";
    pointer-events: none;
  }

  &--disabled {
    @apply cursor-not-allowed;

    &:not(.c-card--current) {
      @apply opacity-50;
    }

  }

  &--new {

    &::before {
      @apply border-dashed border-gray-500;
    }

  }

  &--link {
    @apply py-3;
    padding-right: 40px;

    &:hover::before {
      @apply border-gray-500;
    }
  }

  &--popover {
    @apply block rounded-none;
    width: 300px;

    &::before {
      @apply border-0;
    }

  }


  &__arrow {
    @apply absolute text-gray-600 bg-gray-100 rounded-md opacity-0;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    padding: 3px;

    transition: all 0.2s ease;

    .c-card:hover & {
      @apply opacity-80 text-gray-600;
      right: 8px;
    }

    &:hover {
      @apply bg-gray-200 opacity-100 !important;
    }

    .c-card--selectable &,
    .c-card--selectable &:hover {
      @apply bg-blue-100 text-blue-500 !important;
    }

  }

  &--selectable {

    &:not(.c-card--disabled) {
      @apply cursor-pointer;

      &:hover::before {
          @apply border-blue-500;
        }

      }

  }

  &--selected {
    @apply bg-blue-100;

    &::before {
      @apply border-solid border-2 border-blue-500 !important;
    }

  }

  &__selected-indicator {
    @apply absolute w-5 h-5;
    @apply bg-blue-500 text-white rounded-full;
    @apply border-2 border-gray-50;

    left: -10px;
    top: -10px;
    animation: scale-up 0.05s ease;
    border-right-color: white;

    /* Rotate the indicator to align the border colours.. */
    transform: rotate(45deg);

    svg {
      @apply h-3 w-3;
      transform: rotate(-45deg); /* ...and back */
      margin: 2px;
    }

  }

  &__current-indicator {
    @apply absolute text-center w-full;
    top: -10px;
    left: 0;
    line-height: 1; /* remove gap above pill */

  }

  &--error {

    &::before {
      @apply border-red-500;
    }

    .c-card__selected-indicator {
      @apply bg-red-500;
    }

  }

}


.c-distro-card {

  @apply my-1 mx-2 text-center;
  min-height: 88px;

  &__title {
    @apply mt-1 mb-4 flex items-center justify-center;
  }

  &__avatar {
    /* pull the header left a bit to make it visually balanced */
    @apply mr-2 -ml-3;
  }

  &__version-select {
    @apply w-full;
  }


}
