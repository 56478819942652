.c-chip {
  @apply flex flex-row text-gray-800;

  &--dark {
    @apply text-gray-100;
  }

  &--inline {
    @apply inline-flex;
  }

  &__avatar {
    @apply flex-shrink-0;
  }

  &__content {
    @apply flex flex-col ml-4;
    align-self: center;
  }

  &__special {
    @apply w-4 h-4 ml-1 text-gray-500;
  }

  &__title {
    @apply text-sm leading-5 flex items-center text-left;

    /* This can be overriden with u-force-wrap by clamplines */
    @apply break-normal;

  }

  &__link {

    &, &:hover {
      @apply text-gray-800;
    }

  }

  &__secondary {
    @apply leading-4 flex-grow whitespace-no-wrap text-gray-600 text-left;
  }

}
