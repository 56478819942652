.c-summary {
    @apply flex flex-col;

    &__item {
        @apply pb-3 flex justify-between items-center;

        &:not(:last-child) {
            @apply border-b mb-3; 
        }

        &-value {
            @apply rounded-full text-white bg-gray-600;
            @apply leading-8 text-center px-3;

            &:hover {
                @apply text-white bg-gray-700;
            }
        }

        &-label {
            @apply text-sm;
        }



    }


}