.c-related-indicator {
  @apply inline-flex flex-row items-center h-6;

  &__list {
     @apply inline-flex h-4;
  }

  &__item {
    @apply w-4 h-4 rounded-lg border-2 border-white bg-gray-600 cursor-pointer;
    @apply relative;
    margin-left: -4px;

    &:hover:after {
      @apply rounded-lg absolute w-full h-full;
      transition: all 1s ease;
      content: '';
      background-color: rgba(0,0,0,0.33);
    }
  }

  &__ellipsis {
    @apply text-gray-600 w-6 h-6;
  }

  &__count {
    @apply ml-1;
  }

}

.c-related-popover {
  @apply overflow-hidden rounded-md;

  &__items {
    @apply overflow-hidden overflow-y-auto;
    max-height: 250px;
  }

  &__footer {
    @apply flex flex-row bg-gray-50 border-t;
  }

  &__edit-link {
    @apply text-blue-500 text-sm font-bold text-right p-3 flex-grow;
  }

  hr:last-child {
    visibility: hidden;
  }

}