
/* prevent attribute auto-select when contained in a selector */
[class*="--selectable"] * {
    user-select: none !important;
}

.c-avatar {

    /* Colour chip avatars in selector cards */
    .c-card--selected &,
    .c-card--selectable:hover &,
    .c-resource-list__row--selected:not(.c-resource-list__row--disabled) &,

    /* for highlighted global search results */
    *:focus & {
        @apply bg-blue-500 text-white !important;
     }

}

/* Fix issue where tooltips aren't hidden onmouseleave */
select:disabled {
    pointer-events: none;
}

textarea {
    resize: both;
}
