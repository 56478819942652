.c-separator {
    @apply block relative;
    @apply mt-8 mb-4 w-full;
    @apply border-t border-gray-300;
    @apply text-gray-600 text-center text-sm leading-5;

    &__text {
        @apply relative px-2 py-1 rounded-full bg-white;
        top: -0.85em;

        .c-separator--solid & {
            @apply px-5;
            @apply text-xs font-bold tracking-wide text-gray-50 bg-gray-500;
        }

    }



}