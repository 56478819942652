.c-toggle-group {
  @apply inline-block text-sm leading-4;
  @apply bg-gray-100 rounded-md;
  box-shadow: inset 0px 0px 0px 1px var(--gray-500);

  &--full-width {
    @apply flex;
  }

  &__option {
    @apply inline-block;
    @apply px-5 py-3 border-r border-gray-500 text-gray-700 text-center;
    @apply cursor-pointer;
    margin-left: -1px;

    .c-toggle-group--full-width & {
      @apply flex-auto;
    }

    &:first-child {
      @apply rounded-l-md;
    }

    &--is-disabled {
      @apply cursor-not-allowed text-gray-600;
    }

    &:last-child {
      @apply rounded-r-md;
      margin-right: 0;
    }

    &--is-selected {
      @apply text-gray-800 font-bold bg-white;
      @apply border-none;

      /* Wrap a 2px gray-800 line around selected option */
      box-shadow: inset 0px 0px 0px 2px var(--gray-800);
    }    

    
  }

}
