.c-selector-summary {
  @apply flex inline-block ml-2;

  /* when displayed in multi-select list */
  th & {
    @apply ml-0 mr-0;
  }

  &__content {
    @apply inline-flex items-center normal-case;
    @apply bg-gray-200 text-gray-500 rounded-full px-4 leading-7;

    .c-selector-summary--active & {
      @apply bg-blue-500 text-white;
    }

    .c-selector-summary--dropdown & {
      @apply cursor-pointer pr-2;
    }
  }

  &__icon {
    @apply w-4 h-4;
    margin-left: 5px;
  }

  &__text {
    @apply text-sm font-bold;

    .c-selector-summary--active & {
      word-spacing: 0.15em;
    }
  }
}