
.c-firewall-rule {
    @apply flex flex-row items-center;

    &__port {
        @apply font-mono bg-gray-200 rounded-md text-sm px-1 ml-2;
    }

    &__wildcard {
        @apply text-gray-600;
    }
}