.c-auth {
    /* Used for login, 2fa entry interstitial, forgot/reset password, signup forms, collab signup */

    &__heading {
        @apply mb-8;
    }

    &__form {
        @apply relative;
        @apply bg-white;
        @apply ml-16;
        max-width: 400px;
    }

    &__input {
        @apply w-full mb-6 !important;
    }

    &__button {
        @apply w-full mb-3;
    }

    &__invite-card {
        @apply w-full mb-6 shadow-md;

            &::before {
              @apply border-gray-500 !important;
            }

    }

    &__collab-checklist {
        @apply list-none mt-6;

        &-heading {
            @apply font-bold text-lg relative flex flex-row items-center;

            &::before {
                @apply inline-block rounded-full bg-gray-600 text-white w-7 h-7;
                @apply mr-2 text-center align-middle leading-7 font-normal;
                content: counter(step-counter);

                .c-auth__collab-checklist-item--active & {
                    @apply bg-gray-700;
                }

            }

            .c-auth__collab-checklist-item--completed & span {
                @apply line-through opacity-50;
            }

        }

        &-item {
            counter-increment: step-counter;
            @apply text-sm;
            @apply -ml-6 pl-6 pr-5 py-4;
            @apply rounded-lg;

            &--active {
                @apply bg-gray-200 py-6 my-2;
            }

            p:first-of-type {
                @apply mt-4;
            }

        }

    }

}