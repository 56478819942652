.c-modal {
  @apply fixed top-0 left-0 w-full h-full mt-16;
  @apply flex flex-row justify-center;
  z-index: 1082;

  &__popup {
    @apply bg-white rounded-md shadow-xl overflow-hidden;
    
    &-header {
      @apply flex justify-between px-6 pt-4;
    }

    &-body {
      @apply px-6 pt-4 pb-6 text-sm;
      @apply overflow-y-auto;
      max-height: 480px;
    }

    &-footer {
      @apply py-3 px-6 flex justify-between items-center border-t bg-gray-50;
    }

    &-buttons {

      > * + * {
        @apply ml-4;
      }

    }

    &-info {
      @apply text-sm;
    }

  }

  &__close {
    margin-right: -12px;
    margin-top: -4px;
  }

&__content {
   @apply flex-grow;
   max-width: 480px;

  &--wide {
    max-width: 680px;
  }
 }
}