.c-notice {
  @apply py-4 px-6 flex flex-row rounded-md text-sm text-gray-700;

  &__icon {
    @apply w-5 h-5 mr-4 flex-shrink-0 text-blue-500;

    .c-notice--info & { @apply text-blue-500 }
    .c-notice--warning & { @apply text-orange-500 }
    .c-notice--error & { @apply text-red-500 }

    .c-notice--sm & {
      @apply mr-2;
    }
    
  }

  &--info {
    @apply bg-blue-100 border border-blue-300;
  }

  &--error {
    @apply bg-red-100 border border-red-300; 
  }

  &--warning {
    @apply bg-orange-100 border border-orange-300;
  }

  &--sm {
    @apply py-1 px-2 border-none inline-flex items-center;
  }

  &--dev {
    @apply bg-gray-100 mb-4 border border-dashed border-gray-400;
  }
}
