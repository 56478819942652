.c-list-grid-selector {
  @apply flex flex-row items-center;
  @apply bg-gray-100 border border-gray-400 rounded-md;
  @apply h-8 overflow-hidden;

  &__mode {
    @apply text-gray-500;
    @apply cursor-pointer box-content py-1 px-2;

    &:first-child {
      @apply border-r;
    }

    &:last-child {}

    &:hover:not(&--is-active) {
      @apply text-gray-600;
    }

    &--is-active {
      @apply text-gray-700 bg-white;

    }


  }


}
