:root {
    --sidebar-width: 280px;
    --top-bar-height: 64px;
    --card-width: 290px;
    --focus-shadow-width: 3px;
    --toast-height: 60px;
    --toast-width: 320px;
    --input-height: 44px;
    --input-height-sm: 36px;
    --input-height-xs: 32px;
    --button-height: var(--input-height);
    --button-height-sm: var(--input-height-xs);
    --button-height-xs: 28px;
    --checkbox-size: 16px;
    --banner-height: 56px;
}