.c-settings {
  @apply text-sm border rounded-md w-full;
  @apply bg-white border-gray-400;
}


.c-settings-item {
  @apply px-6 py-3;
  @apply flex justify-between items-center;
  min-height: 60px;

  &:not(:last-child) {
    @apply border-b border-gray-400;
  }

  &__name {
    @apply font-bold text-gray-700;
    @apply w-56 flex-shrink-0 pr-3;
  }

  &__value {
    @apply text-gray-600;
    @apply flex-grow flex-shrink;
    @apply text-left break-words overflow-hidden;
  }

  &__edit-link {
    @apply text-right flex-shrink-0;
    min-width: 6rem;
  }

  &__notice {
    @apply w-full pt-2;
  }

  @screen lg {
    @apply flex-wrap;

    &__name {
      flex-basis: 100%;
    }

    &__value {
      flex-basis: 50%;
    }

    &__edit-link {
      @apply self-start;
    }

  }

}

.c-settings-table {
  @apply w-full;

  th, td {
    @apply py-4;
  }

  th:first-child {
    @apply rounded-l-md;
  }

  th:last-child {
    @apply rounded-r-md;
  }

}