/* inspired by: https://codepen.io/dylanraga/pen/Qwqbab */

.c-checkbox {
    @apply inline-flex justify-start items-start cursor-pointer align-middle;

    &--with-label {
        @apply mt-3;
    }

    &--sm {
        @apply text-xs leading-6;
    }

    &--inline {
        @apply inline-flex;
        vertical-align: top;

        &:not(:last-child) {
            @apply mr-8;
        }
    }

    &--disabled {
        @apply cursor-not-allowed opacity-50;
    }

    &--hover-visible  {
        @apply opacity-0;

        tr:hover &  {
            @apply opacity-100;
            transition: opacity 160ms;
        }
    }

    &__control {
        @apply h-0 w-0 appearance-none;
    }

    &__box {
        @apply block rounded-sm border relative flex-shrink-0;
        @apply bg-white border-gray-600;
        transition: all 160ms cubic-bezier(.7,.0,.23,1);
        width: var(--checkbox-size);
        height: var(--checkbox-size);

        .c-checkbox--has-error & {
            @apply border-red-500;
        }

        .c-checkbox--with-label & {
            @apply mt-1;
        }

        .c-checkbox__control:focus ~ & {
            box-shadow: 0 0 0 var(--focus-shadow-width) var(--focus-shadow-color);
        }

        .c-checkbox:not(.c-checkbox--disabled):not(.c-checkbox--checked):hover & {
            @apply border-gray-700;
        }

        /* Check box background */
        &::before {
            @apply w-full h-full absolute;
            @apply bg-gray-700;
            content: "";
            transform: scale(0);
            transition: all 120ms cubic-bezier(.7,.0,.23,1);

            .c-checkbox--blue & {
                @apply bg-blue-500;
            }
        }

        .c-checkbox--checked & {
            @apply border-gray-700;

            &::before {
                transform: scale(1);
            }
        }

        .c-checkbox--checked.c-checkbox--blue & {
            @apply border-blue-500;
        }

    }

    /* Check/tick icon */
    &__check {
        @apply absolute text-white;
        width: calc(var(--checkbox-size) - 2px);
        height: calc(var(--checkbox-size) - 2px);
    }

    &__description {
        @apply ml-3;
        @apply text-gray-700;
    }

    &__helper-text {
        /* todo: link break this element */
        @apply text-gray-700;
        flex-basis: 100%;
        width: 0;
        .c-checkbox--has-error & {
            @apply text-red-500;
        }
    }

}
