
.u-width {

    &--card {
        width: var(--card-width);
    }

    &--full {
        width: 100%;
    }

}

/* Utility classes for formatting different data display types */

.u-data {

    &--ip-address {
        @apply tracking-tighter;
        font-size: 15px;
    }

    &--sha256 {
        @apply font-mono tracking-tighter;
    }
}

.u-force-wrap {
    word-break: break-all !important;
    overflow-wrap: anywhere !important;
}