.c-pager {
  @apply flex flex-row items-center justify-between;

  &__options, &__nav {
    @apply flex flex-row items-center;
  }

  &__nav > * + * {
    @apply ml-2;
  }

  /*  count of items in list */
  &__summary {
    @apply text-gray-600 text-sm mr-6;
  }

  /* Select for number of items per page */
  &__page-size {}

  /* Overrides .c-button for pager buttons */
  &__button {
    @apply px-3 !important;

    &--active {
      @apply border-gray-600 bg-gray-600 text-white !important;
    }

  }

}

