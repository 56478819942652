.c-status {
  @apply inline-block text-xs leading-6 rounded-full px-3 whitespace-no-wrap;
  @apply cursor-default;

  &--green { @apply bg-green-200; }
  &--red { @apply bg-red-200; }
  &--orange { @apply bg-orange-100; }
  &--gray { @apply bg-gray-100; }



  &__icon {
    @apply rounded-full relative;
    @apply select-none;
    @apply mr-2;
    width: 10px;
    height: 10px;

    .c-status--for-avatar & {
      @apply bg-white;
      @apply m-0;
      @apply absolute;
      right: -2px;
      bottom: 2px;
    }

    &:after {
      @apply absolute rounded-full;
      content: '';
      width: 6px;
      height: 6px;
      left: 50%;
      margin-left: -3px;
      top: 50%;
      margin-top: -3px;
    }

    .c-status--green &:after { @apply bg-green-500; }
    .c-status--orange &:after { @apply bg-orange-500; }
    .c-status--red &:after { @apply bg-red-500; }
    .c-status--gray &:after { @apply bg-gray-500; }

    .c-status--animate &:after {
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: fade-in-out;
    }

    .c-status--unknown & {
      display: none;
    }


  }

  &__text {
    .c-status--green & { @apply text-green-500 }
    .c-status--orange & { @apply text-orange-500; }
    .c-status--red & { @apply text-red-500; }
    .c-status--gray & { @apply text-gray-500; }
  }

  &--for-avatar {
    @apply p-0;
  }

}










