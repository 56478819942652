table {}

th, td {
    @apply px-4 text-left;
  
    &:first-child {
        @apply pl-8;
    }
  
    &:last-child {
        @apply pr-8;
    }
}

th {
    @apply uppercase leading-4;
    @apply text-gray-700 bg-gray-50 border-b;
    font-size: 12px;
    height: 44px;
}

td {
    @apply py-4;

    tr:not(:last-child) & {
        @apply border-b;
    }

}

