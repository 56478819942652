.c-chart {
  @apply bg-white border rounded-lg;
  @apply px-3 pt-4 pb-3;

  /* override panel__section automatic vertical margins */
  margin-top: 0 !important;

  &__title {
    @apply mb-3 ml-3 leading-7;

    &-measurement {
      @apply text-gray-500 ml-1 not-italic;

    }
  }

  &__tooltip {
    @apply align-middle mb-1;
  }

  &__container {
    @apply flex text-xs rounded-md;

    height: 240px;

    /* smaller than xxl */
    @screen xxxl {
      & {
        height: 200px;
      }
    }

  }

  &__placeholder {
    @apply flex bg-gray-100 text-gray-600 w-full p-8 text-sm text-center rounded-lg;
  }

  &__error, &__spinner {
    @apply self-center mx-auto;
  }

  &__spinner {
    @apply text-gray-500;
  }

  &__legend-item {
    @apply text-gray-700 text-sm;
    margin-left: 2px;
    cursor: pointer;
  }

  &-group {
    @apply grid gap-8 w-full;
    overflow-y: hidden;

    grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));

    @screen xxxl {

      & {
        grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
      }

    }

  }


}



