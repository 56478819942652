.c-resource-list {
    @apply w-full text-left;
    @apply bg-white;

    &__header,
    &__item {
        @apply whitespace-no-wrap;

        /* row actions */
        &--actions {
            @apply text-right;
        }

        &--min {
            min-width: 0;
            width: 0;
        }

        &--selector {
            @apply pl-4 pr-0 !important;
            min-width: 0;
            /* Padding value must equal left padding above */
            width: calc(var(--checkbox-size) + 16px) !important;
            user-select: none;

        }

        &--stretch {
            width: 100%;
        }

        /* Hide columns from listings as screen size is reduced */

        @screen xxl {

            &:nth-child(n+6):not([class*="--actions"]) {
                @apply hidden;
            }

            &--resource-name {
                max-width: 520px;
            }

        }

        @screen xl {

            &:nth-child(n+5):not([class*="--actions"]) {
                @apply hidden;
            }

            &--resource-name {
                max-width: var(--card-width);
            }
        
        }

        @screen lg {

            &:nth-child(n+4):not([class*="--resource-name"]):not([class*="--actions"]) {
                @apply hidden;
            }

            &--resource-name {}

        }

    }

    &__header {

        height: 48px;

        &--sortable span {
            @apply cursor-pointer;
        }
        
        &--sorted-asc span,
        &--sorted-desc span {
            @apply bg-gray-200;
        }

    }

    &__item {

        /* When table is used as inline editor e.g Listeners */
        .c-resource-list--editor & {
            @apply py-5;
            vertical-align: top;
        }

        &--empty {
            @apply text-gray-600 py-12 text-sm;
            @apply whitespace-normal;
        }


        &--resource-name {
            @apply whitespace-normal;

            .c-resource-list__item--selector + & {
                @apply pl-3 !important;
            }
        }

    }

    &__row {
        transition: all 200ms ease-out;

        .c-copy-link {
            visibility: hidden;
        }

        &:hover:not(.c-resource-list__row--disabled):not(.c-resource-list__row--empty):not(.c-resource-list__row--selectable):not(.c-resource-list__row--selected):not(.c-resource-list__row--highlighted) {
                @apply bg-gray-50;
                box-shadow:
                    inset 2px 0 0 0 var(--gray-600);

                .c-copy-link {
                    visibility: visible;
                }
        }

        &--selected:not(&--disabled) {
            @apply bg-blue-100;
            box-shadow:
                    inset 2px 0 0px 0px var(--blue-500);
        }

        &--selectable {
            user-select: none;

            &:not(.c-resource-list__row--disabled) {
              @apply cursor-pointer;
            }

            &:hover:not(.c-resource-list__row--disabled) {
                @apply bg-blue-100;
            }

        }

        &--highlighted {
            @apply bg-yellow-100;
            box-shadow: inset 3px 0 0px 0px var(--orange-500);

            &:hover:not(.c-resource-list__row--disabled) {
                @apply bg-yellow-200;
            }
        }

        &--disabled {
            @apply cursor-not-allowed opacity-50;
        }

        &--empty {}
    }

    &__sort {
        @apply rounded inline-flex items-center;
        padding: 4px 6px;
        margin-left: -4px; /* move header spans back to account for padding */
    }

    &__sort-icon {
        @apply text-gray-500 ml-1;
        width: 12px;
        height: 12px;

        [class*="--sorted"] &,
        [class*="--sortable"] span:hover & {
            @apply text-gray-700;
        }

    }

    &__action {
        @apply text-gray-600;

        &:hover {
            @apply text-gray-700;
        }
    }


}

.c-billing-summary {
    @apply text-xs;
    @apply mx-4 my-3;
    width: calc(100% - 32px);

    &__row {}

    &__item {
        @apply py-6p border-none;

        &:first-child {
            @apply pl-2;
        }

        &:last-child {
            @apply pr-2 text-right;
        }

        .c-billing-summary__row:nth-last-child(2) & {
            @apply border-solid border-b border-gray-300 pb-3;
        }

        .c-billing-summary__row--total & {
            @apply font-bold pt-3;

            i {
                @apply font-normal text-gray-500 !important;
                margin-left: 2px;
            }
        }

        .c-billing-summary__row--discount & {
            @apply text-green-600 font-bold;
        }

    }

}