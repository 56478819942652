.o-spinner {
    @apply relative;
    /* border-color: rgba(0,0,0,0.05); */
    border-color: transparent;

    &, &:after {
      @apply block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border-width: 2px;
    }
  
    &:after {
      @apply absolute;
      left: -2px;
      top: -2px;
      content: '';
      border-color: transparent;
      border-top-color: currentColor;
      border-right-color: currentColor;
      animation: spin .4s linear infinite;
    }

    &--inline {
      @apply inline-block mx-2;
    }
  
    &--xs, &--xs:after {
      background-color: white;
      width: 8px;
      height: 8px;
      border-width: 1px;
      box-shadow: 0 0 0px 1px white;
    }
  
    &--sm, &--sm:after {
      width: 16px;
      height: 16px;
      border-width: 1px;
    }
  
    &--lg, &--lg:after {
      width: 64px;
      height: 64px;
      border-width: 3px;
    }
  
    &--inline {
      @apply inline-flex align-middle mx-2;
    }
  
    &--green:after {
      border-top-color: var(--green-600);
      border-right-color: var(--green-600);
    }
  
  }
