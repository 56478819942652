.c-radio {
  max-width: 640px;

  &--lg {}
  &--has-description {}

  &__item {
    @apply z-0 leading-5 cursor-pointer py-1 ml-2;
    @apply flex flex-row;

    /* defaulting to vertically centered contents here means non-text label
     content (e.g using a TextInput) works better */
    @apply items-center;

    &:not(:last-child) {
      @apply mb-2;
    }

    /* provide a little more spacing for items with a description */
    &--has-description:not(.c-radio--lg *):not(:last-child) {
      @apply mb-3;
    }

    &, * {
      transition: all 0.2s ease-out;
    }

    /* fancy bordered large radio options */
    .c-radio--lg & {
      @apply py-3 px-5 ml-0;
      @apply rounded-lg border border-gray-400;

      &:not(:last-child) {
        @apply mb-3;
      }

      &:hover:not(&--selected) {
        @apply bg-white border-gray-500;
      }

    }

    &--selected {
      @apply z-10;

      .c-radio--lg & {
        @apply bg-white border-gray-700;
      }
    }

    &-indicator {
      @apply w-4 h-4 relative;
      @apply border rounded-full;
      @apply border-gray-500 bg-white;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 2px;
        top: 2px;
        border-radius: 100%;
        background-color: var(--gray-700);
        transition: all 0.2s ease;
        transform: scale(0);
      }

      /*  indicator looks better top-aligned (next to item-name) for items with
      a description - except for the fancy --lg groups */
      .c-radio__item--has-description &:not(.c-radio--lg *) {
          @apply self-start mt-1;
      }

      .c-radio__item:hover & {
        @apply border-gray-600;
      }

      .c-radio__item--selected & {
        @apply border-gray-700;

        &::after {
          transform: scale(1);
        }

      }
    }

    &-content {
      @apply text-sm;

      margin-left: 14px;

      .c-radio--lg & {
        margin-left: 20px;
      }

    }

    &-name {
      @apply font-bold;
    }

    &-description {
      @apply text-gray-600;
    }

  }
}