/* Basic styling for headings */

h1,h2,h3,h4 {
    @apply font-bold;
}


h1 {
    @apply text-2xl leading-10 tracking-tightest;
    word-spacing: 0.08em;
}

h2 {
    @apply text-xl leading-8 tracking-tighter;
    word-spacing: 0.052em;
}

h3 {
    @apply text-lg leading-8 tracking-tighter;
    word-spacing: 0.048em;
}

h4 {
    @apply text-base leading-6 tracking-tight;
    word-spacing: 0.046em;
}
