.c-textarea {
    @apply relative block;

    &:not([class*="w-"]) {
        @apply w-72;
    }


    /* Floating labels which serve as placeholder hints initially */
    &__label {
        white-space: nowrap;
        @apply absolute z-20 font-sans !important;
        transition: all 0.12s;
        pointer-events: none;
        left: 16px;
        top: 16px;

        &,
        .c-textarea--disabled.c-textarea--completed:hover & {
            @apply text-gray-600;
        }

        .c-textarea--has-focus &,
        .c-textarea--completed & {
            top: 0;
            @apply text-xs leading-4 tracking-wide text-gray-600;
            @apply left-0 ml-3 p-0 px-1;

        /* background hack to only mask the input
        outline underneath the label text */
            background: linear-gradient(white, white) no-repeat 0 8px;
        }
        
        .c-textarea--has-focus &,
        .c-textarea--has-error & {
            @apply font-bold;
        }

        .c-textarea--has-focus &,
        .c-textarea--completed:hover & {
            @apply text-gray-700;
        }

        .c-textarea--optional & span {
            @apply text-gray-600 font-normal;
        }

        .c-textarea--has-error &,
        .c-textarea--has-error:hover & {
            @apply text-red-500;
        }

    }

    &__control {
        @apply bg-white text-gray-700 rounded leading-5 relative;
        @apply px-4 py-3 m-0;
        @apply border border-gray-600;
        outline: none;
        z-index: 0;
        min-width: 24rem;
        width: 100%;
    
        /* Allow for the top space left for the floating label */
        margin-top: 8px;
  
        .c-textarea:hover &,
        .c-textarea--has-focus & {
            @apply border-gray-700;
        }

        .c-textarea--has-error & {
            @apply border-red-500 !important;
        }

        .c-textarea--disabled & {
          @apply border-gray-500 text-gray-600 cursor-not-allowed !important;
        }

        &::placeholder {
            @apply text-transparent;
        }
  
    }

    &__helper-text {
        @apply text-xs mt-1 italic text-gray-600;
        @apply mx-4; /* Align helper text vertically with floating label */
      
        .c-textarea--has-error & {
          @apply text-red-500;
        }
      
        /* No floating labels to align with on sm and xs */
        .c-textarea--sm & {
          @apply ml-1;
        }
      
      }


}