.c-nav {
    @apply flex flex-col flex-auto pt-3 pb-6;
    @apply min-h-screen h-full;

    &__logo {
        @apply px-5 mb-6;
    }

    &__list {
        @apply flex-none;
    }

    &__item {
        line-height: 0;
    }

    &__sub-title {
        @apply mt-3 pl-6 py-1 uppercase text-gray-600;
        font-size: 12px;

        @media (max-height: 600px) {
            & {
              @apply hidden;
            }
        }
    }

    &__account {
        @apply relative;
        max-width: var(--sidebar-width);

        &-switch-link {
            @apply absolute block;
            @apply w-10 h-10 rounded-full cursor-pointer bg-white bg-opacity-10;
            @apply border border-transparent;
            @apply text-white text-opacity-50;
            left: -24px;
            top: 0;
            z-index: 0;
            transition: all 0.25s;

            .c-nav__account:hover & {
                @apply w-10 h-10 text-opacity-100 bg-black bg-opacity-40;
                z-index: 2;
                left: -8px;

                &:hover {
                    @apply text-opacity-100;
                }
            }

        }

        &-switch-icon {
            @apply w-6 h-6;
            margin: 8px;
        }

        &-chip {
            @apply ml-6 mt-2 mr-4 mb-3;
            pointer-events: none;
            z-index: 1;
        }
    }   

    &__link {
        @apply flex items-center mx-3 px-3 rounded;
        @apply text-sm text-gray-400;
        line-height: 30px;
        transition: all 0.25s;

        &--current {
            @apply text-white;
            background-color: rgba(255,255,255,0.12);
        }

        &:hover {
            @apply text-white;
        }

    }

    &__icon {
        @apply w-6 h-6 mr-3;
    }


}