.o-skeleton {
  @apply inline-block rounded-full overflow-hidden relative h-3;
  @apply bg-gray-300 !important;

  @keyframes skeleton {
    from {
        left: -200px;
    }
    to   {
        left: 100%;
    }
  }

  &:not(&--no-animation)::before {
    content: '';
    display: block;
    position: absolute;
    left: -200px;
    top: 0;
    height: 100%;
    width: 200px;
    background: linear-gradient(to right, transparent 0%, rgba(255,255,255,0.45) 50%, transparent 100%);
    animation: skeleton 1s cubic-bezier(0, 0.0, 0.2, 0) infinite;
  }

  &--xs { /* distro specs */
    width: 48px;

    &::before {
      animation-duration: 0.85s !important;
    }

  }

  &--sm { /* identifiers */
    width: 72px;
 
    &::before {
      animation-duration: 0.75s !important;
    }

  }

  &--md { /* [Default],related indicators, dates */
    width: 96px;

    &::before {
      animation-duration: 0.75s !important;
    }

  }

  &--lg {
    width: 180px;
  }

  &--xl { /* IPv6 addresses */
    width: 220px;
  }

  &--xxl { /* Titles */
    width: 320px;
  }

  &--full {
    @apply w-full;
  }
}