body {
  @apply antialiased text-gray-700;
  text-rendering: optimizeLegibility;
  }

a {
  @apply text-blue-500;

  &:hover {
    @apply text-blue-600;
  }

  &.a--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

p {

  &:not(:last-child) {
    @apply mb-4;
  }

}

