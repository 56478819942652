.c-checklist {
  @apply text-sm;

  * {
    transition: all 200ms ease-out;
  }

  &__item {
    @apply flex items-center rounded-full -ml-1 -mr-1 relative;
    padding: 6px 8px;

    &--tickable:hover {
      @apply bg-gray-100a;
    }

    &-link {
      @apply flex items-center;
    }

    &-name {
      @apply text-gray-700;

      .c-checklist__item:not(.c-checklist__item--completed):hover & {
        @apply text-gray-800;
      }

      .c-checklist__item--completed & {
        @apply line-through text-gray-500;
      }
    }

    &-arrow {
      @apply text-gray-600 opacity-0 absolute;
      right: 16px;
      height: 20px;
      width: 20px;

      .c-checklist__item:hover & {
        @apply opacity-75;
        right: 8px;
      }
    }
  }

  &__tick {
    @apply rounded-full border border-gray-400 bg-white;
    @apply mr-2 relative;

    width: 18px;
    height: 18px;

    .c-checklist__item--tickable:not(.c-checklist__item--completed) &:hover {
      @apply cursor-pointer;
    }

    .c-checklist__item--completed & {
      @apply border-green-500;
    }

    &-icon {
      @apply absolute opacity-0 text-white rounded-full border;
      width: 16px;
      height: 16px;

      .c-checklist__item--completed &,
      .c-checklist__item--tickable .c-checklist__tick:hover & {
          @apply opacity-100 bg-green-500 border-green-500 !important;
      }

    }
  }
}