:root {
  --gray-50: #F8FAFC;
  --gray-100: #F2F5FA;
  --gray-100a: rgba(25,29,44,0.05);
  --gray-200: #EBEEF5;
  --gray-300: #DADFE8;
  --gray-400: #CED4E0;
  --gray-500: #A7B1C4;
  --gray-600: #7C8494;
  --gray-700: #3D4357;
  --gray-800: #232a3f;
  --gray-900: #191d2c;
  --blue-100: #ebf8ff;
  --blue-200: #b4d3f3;
  --blue-300: #68a6e7;
  --blue-400: #3689df;
  --blue-500: #0676DE;
  --blue-600: #0356ac;
  --blue-700: #024081;
  --blue-800: #022b56;
  --green-100: #F5FFEB;
  --green-200: #EBFADB;
  --green-300: #DBF2C4;
  --green-400: #B0DD85;
  --green-500: #69BA1C;
  --green-600: #5CA419;
  --green-700: #508D15;
  --red-100: #FFF0F0;
  --red-200: #FCE3E3;
  --red-300: #F0C2C2;
  --red-400: #E08282;
  --red-500: #D0021B;
  --red-600: #B70218;
  --red-700: #9E0215;
  --orange-400: #F5AD49;
  --orange-500: #F49C1F;
  --orange-600: #D7891B;
  --orange-700: #B97718;

  --focus-shadow-color: rgba(0,0,0,0.12);
}
