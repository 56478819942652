
.c-identifier {
    @apply text-xs text-gray-600 font-mono;
    user-select: all;
  }

.c-back-link {
  @apply block -mb-2 -mt-2;
}

.c-acme-domain {
  @apply font-mono;

  & + & {
    margin-left: 6px;
  }
}

.c-welcome {
  @apply rounded-md px-8 py-6 mb-10 relative border border-gray-400;
  @apply text-center text-white;
  box-shadow: 0 0 0 4px rgba(0,0,0,0.05);
  background: url('../misc/streamers.svg'), linear-gradient(130deg, #4776e6, #8e54e9);

  &__heading {
    @apply mb-1;
  }

  &__subheading {
    @apply font-normal text-lg text-white text-opacity-80;
  }

  &__dismiss {
    @apply absolute text-white text-opacity-50 !important;
    top: 12px;
    right: 12px;

    &:hover {
      @apply text-opacity-100 !important;
    }
  }
}