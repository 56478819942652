.c-user-nav {

  @apply flex flex-row items-center relative;

  &__item {
    @apply mr-5;

    &:last-of-type {
      @apply mr-0;
    }

  }

  &__avatar,  &__link {
    @apply cursor-pointer;
  }

  &__build-indicator {
    @apply flex flex-row items-center relative;
    @apply px-4 py-2 rounded-md leading-4 text-sm cursor-pointer;
    @apply text-green-600  bg-gray-100;

    &:hover {
      @apply bg-gray-200;
    }

  }

  &__link {
    @apply text-gray-500;
    width: 28px;
    height: 28px;

    &:hover {
      @apply text-gray-600;
    }
  }

  &__menu {
    @apply absolute shadow-md;
    @apply rounded-md bg-white border border-gray-400 py-1;
    z-index: 999; /* place menu in front of other stuff */
    min-width: 220px;
    top: 56px;
    right: 0;


    &-item {
      @apply text-sm;
    }
  
    &-link {
      @apply block text-gray-600 px-5 py-2;
      transition: all 0.25s ease;
  
      &:hover {
        @apply text-gray-700;
      }
  
    }

    &-chip {
      @apply pt-3 pb-2 px-4;
    }

    hr {
      @apply my-1;
    }

  }

}
