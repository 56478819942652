.react-tabs {
  -webkit-tap-highlight-color: transparent;

  /* Where the tab section follows a panel header reduce the space and
    remove the border */
  .c-panel__section--header + & {
    position: relative;
    margin-top: -16px;
  }

  &__tab-list {
    @apply bg-white;
    border-bottom: 1px solid var(--gray-400);
  }

  &__tab {
    @apply text-gray-600 font-bold ml-4 text-sm;
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 16px 16px;
    cursor: pointer;

    &:hover:not(&--disabled) {
      @apply text-gray-700;
    }

    &--selected {
      @apply bg-blue-300;
      @apply text-gray-700;
      border-bottom: 2px solid var(--gray-700);

      background: #fff;
    }

    &--disabled {
      @apply opacity-50 cursor-not-allowed;
    }

    &:focus {
      outline: none;


    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}