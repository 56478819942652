.c-button {
  @apply px-8;
  @apply text-sm font-bold font-sans leading-5 relative;
  @apply inline-flex items-center no-underline;
  height: var(--button-height);

  &, &::before {
    @apply rounded-md;
    transition: all 0.2s;
  }

  &__text {
    @apply whitespace-no-wrap mx-auto z-20;
  }

  &__spinner {
    position: absolute;
    left: 50%;
    margin-left: -8px;
  }

  /* "Secondary" i.e blue outlined is the default button style */
  &, &--secondary {
    @apply border border-blue-500;
    @apply bg-transparent text-blue-500;


    &:hover:not(.c-button--disabled) {
      @apply bg-blue-100;
    }
    
    &:active:not(.c-button--disabled) {
      @apply text-blue-600 bg-blue-200;
    }
  }

  &--disabled {
    @apply cursor-not-allowed opacity-50;
  }

  &:focus {
    @apply outline-none;
    transition: background-color 0.2s, box-shadow 0.1s;
  }

  &:focus:not(.c-button--disabled) {
      /* use solid box shadow to have rounded corners and 
      avoid content jumping on focus */
      box-shadow: 0 0 0 var(--focus-shadow-width) var(--focus-shadow-color);
  }

  &:active {
    @apply outline-none;
  }

  &:not(:active) {
    transition: all 0.2s;
  }

  &--small, &--xs {
    @apply px-5 rounded;
    @apply text-xs leading-5;
    height: var(--button-height-sm);

    &.c-button--icon-only {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &--xs {
    height: var(--button-height-xs);
  }

  &--primary {
    @apply bg-blue-500 border-0 tracking-normal;
    @apply text-white !important;

    &:not(.c-button--small):not(.c-button--icon-only) {
      @apply px-10;
      word-spacing: 0.10em;
      min-width: 170px;

      &:not(.c-button--disabled):not(:active):not(.c-button--loading) {
        text-shadow: 1px 1px 3px rgba(0,0,0,0.10);

        &::before {
          @apply absolute left-0 top-0 w-full h-full;
          content: "";
          box-shadow: inset 0 -3px 0 rgba(0,0,0,0.20);
        }
      }
    }

    &:hover:not(.c-button--disabled) {
      @apply bg-blue-600;

      &::before {
        box-shadow: inset 0 -3px 0 rgba(0,0,0,0.24);
      }

    }

    &.c-button--loading {
      @apply bg-blue-600;
    }

    &:active:not(.c-button--disabled) {
      @apply bg-blue-700;
    }

  }

  &--tertiary,
  &--bare {
    @apply border-0;
  }
  
  &--tertiary {
  
    &:hover:not(.c-button--disabled) {
      @apply text-blue-600 bg-gray-100a;
    }

    &:active:not(.c-button--disabled) {
      @apply text-blue-600 bg-gray-200;
    }
  
  }

  &--bare {
    @apply rounded-none h-auto p-0;
  
    &:hover:not(.c-button--disabled), &:active {
      @apply bg-transparent text-blue-600;
    }

    &:focus:not(.c-button--disabled) {
      box-shadow: none;

      .c-button__text {
        @apply underline;
      }
    }
  }

  &--icon-only {
    padding-left: 10px;
    padding-right: 10px;
  }

  &--loading {
    @apply cursor-not-allowed pointer-events-none;

    .c-button__text,
    .c-button__icon {
      @apply invisible;
    }

  }




/* Colour options for buttons */

  &--green&--primary {
    @apply bg-green-500;
  
    &:hover:not(.c-button--disabled) {
      @apply bg-green-600;
    }

    &:active:not(.c-button--disabled) {
      @apply bg-green-700;
    }  
  }

  &--green {
    @apply border-green-500 text-green-500;
  
    &:hover:not(.c-button--disabled) {
      @apply bg-green-100 text-green-600;
    }

    &:active:not(.c-button--disabled) {
      @apply bg-green-200;
    } 
 
  }


  /* Grey only used for Secondary or tertiary (not primary)
  For example on muted secondary buttons such as "< Back" etc
  */

  &--grey {
    @apply border-gray-500 text-gray-600;
  
    &:hover:not(.c-button--disabled) {
      @apply bg-gray-100a text-gray-600;
    }

    &:active:not(.c-button--disabled) {
      @apply bg-gray-200;
    }
 
  }


  /* Currently used for pager buttons  */

  &--dark-grey {
    @apply text-gray-600 border-gray-600 bg-white;

    &:hover:not(.c-button--disabled) {
      @apply border-gray-700 text-gray-700 bg-white;
    }

  }


  /* For use on darker backgrounds such as banners */
  &--white {
    @apply border-white text-white;
  
    &:hover:not(.c-button--disabled) {
      @apply text-white;
      background-color: rgba(255,255,255,0.25);
    }

    &:active:not(.c-button--disabled) {
      @apply text-white;
      background-color: rgba(255,255,255,0.08);
    }
 
  }



  /* Red is designed for "danger" actions */

  &--red&--primary {
    @apply bg-red-500;
  
    &:hover:not(.c-button--disabled) {
      @apply bg-red-600;
    }

    &:active:not(.c-button--disabled) {
      @apply bg-red-700;
    }  
  }

  &--red {
    @apply border-red-500 text-red-500;
  
    &:hover:not(.c-button--disabled) {
      @apply bg-red-100 text-red-600;
    }

    &:active:not(.c-button--disabled) {
      @apply bg-red-200;
    } 
 
  }


/* Icon options for buttons */

  &__icon {
    @apply -ml-2 -mr-2;
    @apply w-5 h-5 !important;

    .c-button--icon-only & {
      @apply m-0;
    }

    &--pre {

      @apply mr-2 -ml-2;

      /* We don't want the pre-icon hanging outside the bounds of bare button */ 
      .c-button--bare & {
        @apply ml-0;
      }
      
    }
    
    &--post  {
      @apply ml-2 -mr-2;
    }
  
  }

  &--hover-visible  {
    @apply opacity-0;
  }

  tr:hover &--hover-visible  {
    @apply opacity-100;
    transition: opacity 160ms;
  }



  /* FIXME: Playing with these micro-interactions, might ditch if annoying */

  &:active:not(.c-button--disabled) &__icon {
    animation: pinch 0.2s forwards ease-out;
  }

}
