/* FIXME: add shadows */

.c-panel {
  @apply border border-gray-400 rounded-lg relative shadow;
  @apply overflow-hidden;

  &--compact {
    @apply rounded-md;
  }

  &__title {
    flex: 1 1 auto;

    &-edit-link {
      @apply ml-2;
    }

    &-edit-button {
      vertical-align: top;
      @apply opacity-35;

      .c-panel__section--header:hover & {
        @apply opacity-60;

        &:hover {
          @apply opacity-100;
        }
      }



    }

    .c-panel--compact & {
        @apply text-lg;
    }

  }

  &__description {
    @apply text-gray-600 leading-6 tracking-tight;
    flex-basis: 100%;
  }

  /* Panel header buttons */
  &__header-actions {
    @apply flex flex-wrap items-start justify-end gap-y-2;
    flex: 1 1 50%;

    > * + * {
      @apply ml-4;
    }

  }

  &__section {
    @apply relative;
    @apply p-8 bg-gray-50;
    @apply overflow-x-auto;

    &:not(:last-child):not(&--no-border), &--force-border {
      @apply border-b border-gray-400;
    }
    &--force-border-top {
      @apply border-t border-gray-400;
    }

    /* Vertically space contents of standard panel sections */
    &:not([class*="--"]) > * + * {
      @apply mt-6;
    }

    &--header, &--view-options {
      @apply bg-white;
      @apply flex flex-wrap justify-between;
    }

    /* Panel header containing title, description and actions */
    &--header {
      @apply py-4 items-start;
      min-height: 64px;
    }

    /* Panel section for search, filters and list/grid buttons */
    &--view-options {
      @apply py-5 items-center;
    }

    /* Panel section for a toggle group filter */
    &--toggle-group {
      @apply pt-8 pb-0 flex flex-wrap justify-center;
    }

    &--no-padding {
      @apply p-0 !important;
    }

    /* Styles for smaller more compact panels eg for sidebars */
    .c-panel--compact & {
      min-height: 0;

      &:not(&--no-padding) {
        @apply px-6 py-4;
      }

      &--header:not(&--no-padding) {
        @apply py-2;
      }

    }

    /* Resource details e.g status, id etc */
    &--resource-details {
      @apply py-2 border-0 !important;
    }

    &--resource-details + & {
      @apply pt-4;
    }

    /* Save/cancel actions when a panel is in edit mode */
    &--button-bar {
      @apply py-4 bg-white flex justify-end items-center;
      min-height: 72px;



      > * + * {
        @apply ml-6;
      }

      &__spacer  {
        @apply flex-1;
      }
    }

    /* Pagination for panel listings
        See c-pager for component  */
    &--pager {
      @apply py-3;
    }

  }

}

/* FIXME: needs work to handle all resource types e.g CloudSQL */

.c-resource-details {
  @apply flex flex-wrap;
  @apply text-left;


  &__item {
   
    &:not(:last-child) {
      @apply mr-20;
    }

    @apply py-4;
  }
  

  &__item-name {
    @apply text-xs uppercase text-gray-600 leading-4 mb-2;
  }

  &__item-value {
    @apply text-sm text-gray-700 leading-4;
  }

}



.c-resource-cloud-ips {
  @apply relative; /* FIXME: not sure what this is for */
}

.c-resource-cloud-ips .c-list-grid-selector {
  @apply absolute right-0 top-0 mr-8 mt-8;
}