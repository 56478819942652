.c-search {


  &__input {
    width: calc(100% - 75px) !important;
    @apply ml-1 my-3 !important;
  }

  &-result {
    /* this "contains" the search result items as the result box transitions
    out */
    transition: max-height 0.2s ease;

    &__header {
      @apply text-gray-500 text-xs uppercase;
      @apply ml-4 mb-1;

      &:not(:first-child) {
        @apply mt-5;
      }

    }

    &__item {
      @apply mx-1 flex flex-row px-3 py-2 items-center text-gray-600;

      &:focus {
        @apply bg-blue-100 rounded-md outline-none;
      }

      &-content {
        @apply flex-grow;
      }

      &-action {
        @apply invisible text-blue-500 ml-2 flex-shrink-0;
        transition: all 0s;
      }

      &:focus &-action {
        @apply visible;
      }
    }
  }
}