.u-animate {

  /* Utility classes for animations */
  &--pulse    { animation-name: pulse; }
  &--pinch    { animation-name: pinch; }
  &--spin     { animation-name: spin; }
  &--scale-up { animation-name: scale-up; }
  &--fade-in { animation-name: fade-in; }
  &--fade-out { animation-name: fade-out; }
  &--fade-in-out { animation-name: fade-in-out; }
  &--zoom-in { animation-name: zoom-in; }
  &--zoom-out { animation-name: zoom-out; }
  &--slide-in-left { animation-name: slide-in-left; }
  &--slide-in-right { animation-name: slide-in-right; }

}

  @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1.05);
    }
  }

  @keyframes pinch {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.97);
    }
  }

  @keyframes scale-up {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes spin {
    to {transform: rotate(360deg);}
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fade-in-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes zoom-in {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes zoom-out {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slide-in-right {
    from {
      transform: translate3d(-110%, 0, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0); 
    }
  }

  @keyframes slide-in-left {
    from {
      transform: translate3d(110%, 0, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0); 
    }
  }

