/* region overall component */
.c-input {
  @apply inline-block relative;

  &:not(:last-child) {
    @apply mr-6;
  }

  &--select {
    min-width: 8rem;
  }

  /* if there is not a width class applied to normal inputs set a default */
  &:not(&--select):not(&--xs):not([class*="w-"]) {
    @apply w-72;
  }

  /* Wrapper element providing border */
  &__outline {
    @apply absolute w-full;
    @apply mt-2 border-gray-600 border rounded bg-transparent;
    pointer-events: none;
    height: var(--input-height);
    z-index: 1;
  
    .c-input:hover &,
    .c-input--has-focus & {
      @apply border-gray-700;
    }
    
    .c-input--has-error & {
      @apply border-red-500 !important;
    }
  
    .c-input--is-disabled & {
      @apply border-gray-500 !important;
    }

  }

  /* wrapper for (label, inlays and input itself) */
  &__content {
    @apply flex flex-row leading-6 items-center flex-no-wrap justify-between;
    @apply bg-white;
  
    /* Allow for the top space left for the floating label */
    margin-top: 8px;
  
    .c-input--is-disabled & {
      @apply cursor-not-allowed;
    }
  
  }

  /* Floating labels which serve as placeholder hints initially */
  &__label {
    white-space: nowrap;
    @apply absolute opacity-0 top-0;
    visibility: hidden; /*  avoids accidentally hovering on invisible hints */
    left: 16px;
    z-index: 2;
    transition: all 0.16s;
    word-spacing: 0.06em;

    &,
    .c-input--is-disabled.c-input--completed:hover & {
      @apply text-gray-600;
    }

    .c-input--has-focus &,
    .c-input--completed & {
      @apply text-xs leading-4 text-gray-600 opacity-100;
      @apply left-0 ml-3 p-0 px-1;
      visibility: visible;

      /* background hack to only mask the input
      outline underneath the label text */
      background: linear-gradient(white, white) no-repeat 0 8px;
      background-size: 100% 1px;

    }
    
    .c-input--has-focus & {
      @apply font-bold tracking-tight;
      word-spacing: 0.025em;
    }

    .c-input--has-focus &,
    .c-input--completed:hover & {
      @apply text-gray-700;
    }

    .c-input--has-error.c-input--has-focus &,
    .c-input--has-error.c-input--completed & {
      @apply text-red-500;
    }

    .c-input--optional & span {
      @apply text-gray-600 font-normal;
    }


  }


  /* "inlays" are icon/text prefix/suffixes */
  &__inlay {
    @apply text-gray-500;
    z-index: 3;
  
    &--pre-text, &--pre-icon {
      @apply mr-1 ml-4;
    }
  
    &--pre-text {
      @apply hidden; /* Hide prefixed text initially */
    
      /* Reveal prefixed text on focus and when completed */
      .c-input--has-focus &,
      .c-input--completed & {
        @apply block;
      }
    }
  
    &--pre-icon, &--post-icon {
      @apply w-6 h-6 text-gray-600;
    }
  
    &--post-text, &--post-icon {
      @apply ml-3;
    }
  
    &--post-text {
      @apply flex-none mr-4;

      .c-input--has-post-action & {
        @apply text-xs font-bold text-blue-500 cursor-pointer;

        &:hover {
          @apply text-blue-700;
        }
      }

    }
  
    /* Bit hacky this, but works for now */
    &--post-icon {
      @apply ml-1 mr-3;

      .c-input--has-post-action & {

        &:hover {
          @apply cursor-pointer;
        }
      }

      .c-input--select & {
        @apply absolute mr-2 w-5 h-5;
        @apply text-gray-500;
        pointer-events: none !important;
        right: 0;
      }

      .c-input--select:hover:not(.c-input--is-disabled) & {
        @apply text-gray-700;
      }
    }
  }

  /* The actual form control (input/select) */ 
  &__control {
    @apply flex-auto bg-white;
    @apply px-4 py-10p;
    outline: none;
    z-index: 0;
    transition: opacity 0.2s;
    min-width: 0 !important;
    width: 0;

    .c-input--ellipsis & {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .c-input--auto-populated & {
      @apply font-mono text-gray-800;

      &::first-line {

        @apply bg-gray-100;

        /*
        text-decoration: underline;
        text-decoration-color: var(--green-500);
        */
      }
    }

    /* Override webkit autofill styles */
    &,&:-webkit-autofill::first-line {
      @apply text-base text-gray-700 font-sans;
    }

    &::placeholder {
      transition: all 0.2s ease-out;
      @apply text-gray-600 font-normal;
      @apply opacity-100; /* Needed for Firefox placeholders */

      /* When the input has a label and currently "floating", hide the
      placeholder with opacity to allow for css transition "fade" effect */
      .c-input--has-label.c-input--has-focus:not(.c-input--xs):not(.c-input--sm) &,
      .c-input--has-label.c-input--completed:not(.c-input--xs):not(.c-input--sm) & {
        @apply opacity-0;
      }

    }

    /* When the input has preText visible (hidden by default) remove left
    padding so the entered value appears right after to the preText */
    .c-input--has-pre-text.c-input--has-focus &,
    .c-input--has-pre-text.c-input--completed & {
      @apply pl-0;
    }

    /* When there's a preIcon, remove left padding on input so the entered
    value appears right next to the icon */
    .c-input--has-pre-icon & {
      @apply pl-0;
    }

    .c-input--has-post-text &,
    .c-input--has-post-icon & {
      @apply pr-1;
    }

    .c-input--is-disabled & {
      @apply text-gray-600 cursor-not-allowed;
      opacity: 1;
    }

    select& {
      @apply appearance-none;
      height: var(--input-height);
      max-width: 100%;
      padding-right: 40px;
      width: auto;
    }

  }


  &__helper-text {
    @apply text-xs mt-1 italic text-gray-600;
    @apply mx-4; /* Align helper text vertically with floating label */
  
    .c-input--has-error & {
      @apply text-red-500;
    }
  
    /* No floating labels to align with on sm and xs */
    .c-input--sm &,
    .c-input--xs & {
      @apply ml-1;
    }
  
  }


  /* Don't float labels on Small and Extra small inputs */
  &--sm,
  &--xs {
  
    .c-input__outline,
    .c-input__content {
      margin-top: 0;
    }

    &.c-input--has-focus,
    &.c-input--completed {

      .c-input__label {
        @apply hidden;
      }
    }
  }


  /* Small size overrides */

  &--sm {

    .c-input__outline,
    select.c-input__control {
      height: var(--input-height-sm);
    }

    .c-input__inlay {
  
      &--pre-icon, &--post-icon {
        @apply w-5 h-5;
      }

    }  
  
    .c-input__control {
      @apply py-6p;
    }
  
    &.c-input--has-pre-icon {
  
      .c-input__label {
        left: 40px;
      }
  
    }
  
  }


  /* Extra Small size overrides */
  /* Note: hardcoded width for <input> in gui/src/component/element/Input.js */

  &--xs {
    min-width: 4.5rem;

    .c-input__outline,
    select.c-input__control {
      height: var(--input-height-xs);
    }
  
    .c-input__outline,
    .c-input__content {
      margin-top: 0;
    }

    .c-input__inlay {
  
      &--pre-icon, &--post-icon {
        @apply w-4 h-4 !important;
      }
  
      &--pre-icon, &--pre-text {
        @apply ml-3;
      }
  
      &--post-text, &--post-icon {
        @apply mr-2 ml-0 text-sm;
      }
  
    }  
  
    .c-input__control {
      @apply py-1;
      @apply text-sm;

      &:not(select) {
        padding-left: 10px;
        padding-right: 10px;
        width: 0;
      }

    }
    
    &.c-input--has-pre-text,
    &.c-input--has-pre-icon {

      .c-input__control {
        @apply pl-0;
      }

    }

    &.c-input--has-post-text:not(.c-input--select),
    &.c-input--has-post-icon:not(.c-input--select) {

      .c-input__control {
        @apply pr-1;
      }

    }
  
    &.c-input--select {
      min-width: 6rem;
    }

    .c-input__label {
      left: 10px;
    }
  
    &.c-input--has-pre-icon {
  
      .c-input__label {
        left: 30px;
      }
  
    }
    
  }

  &--borderless {
    .c-input__outline {
      @apply border-0 !important;
    }

    .c-input__content {
      @apply rounded;
    }
  }
}
