/* region overall component */
.c-file-input {
  @apply p-5 mt-6 relative box-border text-center;
  @apply border-2 border-dashed rounded-lg text-gray-600 bg-gray-100 border-gray-400;
  transition: all 0.2s;

  & + & {
    @apply mt-8;
  }

  &--drag-over {
    @apply bg-blue-100 border-blue-500;
  }

  &__title {
    @apply mb-2 text-gray-700;
  }

  &__label {
    @apply cursor-pointer;

    strong {
      @apply mr-1;
    }

  }

  &__clear {
    @apply ml-2 text-gray-600;
  }

  &__control {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }




}














