.c-avatar {
  @apply bg-gray-600 relative rounded-full w-10 h-10 flex items-center justify-center text-white;

  &--object {
    @apply bg-gray-200 text-gray-600;
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__icon {
    @apply w-6 h-6;
  }

  &__gravatar {
    @apply rounded-full;
  }

  &__letter {
      @apply inline-block text-lg leading-10 uppercase;
  }
}
