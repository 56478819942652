.c-page {
  @apply pt-8 pb-20 px-10;

  &__title {
    @apply mx-5;
  }

  &__header {
    @apply flex flex-wrap items-center justify-between gap-y-2;
  }

  /* Vertically space page contents */
  > * + * {
    @apply mt-8;
  }

}