@import 'rc-tooltip/assets/bootstrap.css';

:root {
  --tooltip-bg: rgba(0,0,0,0.8);
}

.rc-tooltip {
  @apply text-xs leading-4;
  opacity: 1; /* override overall tooltip opacity */
  z-index: 99999; /* hack z-index to prevent them appearing behind modals */

  &-inner {
    @apply text-center tracking-wide leading-4;
    background-color: var(--tooltip-bg);
    max-width: 46ch;
    word-spacing: 0.06em;
    padding: 10px 12px;
  }

  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    border-top-color: var(--tooltip-bg);
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    border-right-color: var(--tooltip-bg)
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    border-left-color: var(--tooltip-bg);
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    border-bottom-color: var(--tooltip-bg);
  }

  /* Used with dropdown buttons */
  &--light {
    opacity: 1;
    padding: 0;
  
    .rc-tooltip-arrow {
     display: none;
    }
  
    .rc-tooltip-inner {
      @apply bg-white opacity-100 p-0 border rounded-md text-left;
      @apply text-gray-800 shadow-md;
    }
  }

  &:not(&--light) {

    a {
      @apply text-white underline font-bold;
      white-space: nowrap;
  
      &:hover {
        @apply border-none no-underline;
      }
    }
  
  }
}
