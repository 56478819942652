.c-progress {

    @keyframes progress {
        from {
            left: -80%;
        }
        to   {
            left: 65%;
        }
      }

    &--has-explanation {
        @apply mt-4;
    }

    &__bar, &__indicator {
        @apply rounded-full;
        height: 6px;
    }

    &--shimmer {
        @apply relative overflow-hidden;
    }

    &__bar {
        @apply relative bg-gray-300;
        width: 100%;
    }

    &__indicator {
        @apply absolute left-0 top-0;
        @apply bg-gray-600;
        transition: all 0.4s linear;


        .c-progress--blue & { @apply bg-blue-500; }
        .c-progress--green & { @apply bg-green-500; }

        .c-progress--shimmer &::before {
            content: '';
            display: block;
            position: absolute;
            left: -200px;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(to right, transparent 0%, rgba(255,255,255,0.8) 60%, transparent 100%);
            animation: progress 1.2s cubic-bezier(0, 0, 0.4, 0.2) infinite;
        }

    }

    &__explanation {
        @apply text-xs text-gray-600 pl-2 mt-1;
    }

}