.c-type-card {
  @apply text-center;
  min-height: 80px;
  
  &__title {
    word-spacing: 0.1em;
  }

  &__id {
    @apply mb-3;
    @apply font-normal font-mono text-sm text-gray-600;
    margin-top: -2px;
  }

  &__details {
    @apply flex;
  }

  &__detail-item {
    @apply flex-grow mt-2 leading-4 text-center;
  }

  &__value {
    @apply text-xs text-gray-700;
  }

  &__label {
    @apply text-gray-600 uppercase leading-6 text-xs;
  }

}



