.Toastify {
/* Original: react-toastify/dist/ReactToastify.css' */

  &__toast-container {
    @apply fixed;
    z-index: 9999;
    width: var(--toast-width);

    &[class*="--top"]    { top: 1rem; }
    &[class*="--bottom"] { bottom: 1rem; }
    &[class*="-right"]   { right: 1rem; }
    &[class*="-left"]    { left: 1rem; }
    &[class*="-center"] {
      left: 50%;
      margin-left: calc(var(--toast-width)/-2);
    }

    &--top-right {
      margin-top: 52px; /* move down a little to avoid top nav */
    }
  }


  &__toast {
    @apply relative flex justify-between overflow-hidden cursor-pointer;
    @apply rounded-md px-6 py-4 mb-4 text-sm leading-4;
    @apply bg-black bg-opacity-75 text-white shadow-lg;
    min-height: var(--toast-height);
    max-height: 800px;
  
    &--default {}
    &--info {}

    &--success {}

    &--warning {
      @apply border-orange-500;
    }

    &--error {
      @apply bg-red-500;
    }

  }


  &__toast-body {
    @apply my-auto flex-1;
  }


  &__progress-bar {
    @apply absolute bottom-0 left-0 w-full origin-left bg-white bg-opacity-50;
    height: 2px;

    &--animated {
      animation: Toastify__trackProgress linear 1 forwards;
    }

    &--success { @apply bg-green-500; }
    &--warning { @apply bg-orange-500; }

  }

  &__close-button {
    @apply text-white bg-transparent outline-none cursor-pointer self-start;
    @apply ml-3 -mr-3 w-7 h-7 p-1 rounded-md opacity-50;
    transition: 0.3s ease;

    &:hover {
      @apply bg-black bg-opacity-15 opacity-100;
    }

  }


}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

