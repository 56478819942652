.c-breadcrumbs {
    @apply mt-3 items-center flex flex-wrap text-sm flex-shrink-0 leading-8;

    &__icon {
        @apply mx-1 text-gray-500 block w-5 h-5;

        &:first-child {
            @apply text-current m-0;
        }
    }

    &__link {
        @apply font-bold text-gray-600 px-1;

        &:first-child {
           @apply -ml-2;
        }

        &:hover {
            @apply text-gray-700;
        }
    }

}