.c-page-overlay {
  @apply flex items-center justify-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1081;
  pointer-events: none;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: saturate(40%);
  animation: fade-in 0.2s ease;

  &__content {
    @apply text-white text-center;
    @apply rounded-xl py-6 px-12; 
  }

  &__big-icon {
    @apply rounded-full;
    border: 6px solid white;
    padding: 12px;
    width: 160px;
    height: 160px;
    margin: 0 auto 1.5rem auto;
  }

  @keyframes fade-in {
    from {
      background-color: rgba(0,0,0,0);
    }
    to {
      background-color: rgba(0,0,0,0.6);
    }
  }

}
