.c-banner {
    @apply w-full flex flex-shrink-0 items-center bg-blue-400 text-white;
    box-shadow: inset 0px -1px 0px 0px rgba(0,0,0,0.08);
    height: var(--banner-height);
    animation: banner-appear 0.6s ease;

    @keyframes banner-appear {
      from {
        max-height: 0;
      }
      to {
        max-height: var(--banner-height);
      }
    }


    &--info {
      @apply bg-blue-400 text-white;
    }

    &--warning {
      @apply bg-orange-400 text-white;
    }

    &--error {
      @apply bg-red-500 text-white;
    }

    &__content {
      @apply text-center flex-1 mx-6;
    }

    &__dismiss {
      @apply mr-3 cursor-pointer opacity-75;
      @apply w-6 h-6;

      &:hover {
        @apply opacity-100;
      }

    }

}
