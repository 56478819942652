
.o-icon {
    @apply inline-block w-6 h-6;
    transition: all 0.2s ease;

    div {
        line-height: 0;
    }

    &__svg {
        @apply inline-block w-full h-full;

        &, path {
            fill: currentColor;
        }
    
    }

}

