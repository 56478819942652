.c-pill {
    @apply rounded leading-4 h-5 px-2 ml-1 uppercase;
    @apply inline-flex items-center;
    @apply text-gray-600 bg-gray-100a text-xxs font-bold;
    @apply font-sans;

    a &:hover:not(&--dark) {
        @apply cursor-pointer text-gray-700;
    }

    &--red {
        @apply bg-red-500 text-red-200;
    }

    &--med {
        @apply bg-gray-500 text-white;
    }

    &--dark {
        @apply bg-gray-700 text-gray-200;
    }

  }